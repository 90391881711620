<template>
  <section id="card-navigation">
    <b-row className="mb-3">
      <b-col
          md="8"
          lg="8"
      >
      </b-col>
      <b-col
          md="4"
          lg="4"
      >
        <!--
                <div align="right">
                  <b-button v-if="calendar"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="btn-icon"
                            @click="calendar = false"
                  >
                    <feather-icon icon="CreditCardIcon"/>
                  </b-button>
                  <b-button v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="btn-icon"
                            @click="calendar = true"
                  >
                    <feather-icon icon="CalendarIcon"/>
                  </b-button>
                </div>
        -->

      </b-col>
    </b-row>
    <NewCalendar v-if="state_f"/>
    <!--    <div v-else>
          <div v-if="array_appointments.length > 0">
            <b-row>
              <b-col
                  v-for="(a, index) in array_appointments" :key="index"
                  md="4"
                  lg="4"

              >
                <b-card
                    :header="a.name"
                    :title="a.ResPartner.name"
                    class="text-center"
                    footer-class="text-muted"
                >
                  <b-card-text>
                    {{ a.app_dt_start |  moment("subtract", "6 hours","dddd, MMMM Do YYYY, h:mm:ss a") }} -
                    {{ a.app_dt_stop | moment("subtract", "6 hours","h:mm:ss a") }}
                  </b-card-text>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                  >
                    Ver Cita
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>-->

  </section>

</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton,
} from 'bootstrap-vue'
import Calendar from "@/components/Appointments/Calendar";
import NewCalendar from "@/components/Appointments/NewCalendar";
import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex/dist/vuex.esm.browser";
import {mapGetters} from "vuex";

export default {
  name: "AppointmentList",
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    Calendar,
    NewCalendar
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      calendar: false,
      array_appointments: [],
      name: '',
      state_f: false
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  methods: {
    ...mapActions('appointment', ['findAppointmentUser']),
    async appointmentsAll() {
      this.array_appointments = await this.findAppointmentUser();
    },

  },
  async created() {
    await this.appointmentsAll();
    this.state_f = true
  }

}
</script>

<style scoped>

</style>
